<template>
    <div class="popup-overlay" v-if="isVisible" style="z-index: 999;">
        <div class="popup">
            <h2>{{ $t('popup.title') }}</h2>
            <p>{{ $t('popup.text') }}
                <br><a :href="facebookLink" target="_blank">{{ $t('popup.link') }}</a>
            </p>
            <button @click="closePopup">{{ $t('button.close') }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PopupComponent',
    data() {
        return {
            isVisible: true,
            facebookLink: "https://www.facebook.com/profile.php?id=61553558183045"
        }
    },
    methods: {
        closePopup() {
            this.isVisible = false;
        }
    }
}
</script>

<style lang="scss">
@import '../assets/scss/popup.scss';
</style>