import { createApp } from "vue";
import { createI18n } from 'vue-i18n'
import App from "./App.vue";
import router from "./router";

import pl from './assets/i18n/pl.json';
import en from './assets/i18n/en.json';

const i18n = createI18n({
    locale: 'pl',
    fallbackLocale: 'pl',
    messages: {
        pl,
        en
    }
});

const app = createApp(App);

app.use(router);
app.use(i18n);

router.beforeEach((to, from, next) => {
    const lang = to.params.lang;
    if (lang) {
        i18n.global.locale = lang;
    }else{
        i18n.global.locale = 'pl';
    }
    next();
});

app.mount("#app");