<template>
  <nav class="navbar">
    <div class="logo">
      <router-link :to="$i18n.locale == 'pl' ? '/' : '/en/'">
        <img src="../assets/icons/logo.svg">
      </router-link>
    </div>

    <div class="navbar-flex">
      <div @click="toggleLocale()" class="navbar-language">
          {{ $i18n.locale == 'pl' ? 'ENG' : 'PL' }}
      </div>
      <button class="navbar-toggle" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <ul class="nav-links">
        <li><LocalizedLink to="nasze-psy">{{ $t('nav.ourDogs') }}</LocalizedLink></li>
        <li><LocalizedLink to="mioty">{{ $t('nav.litters') }}</LocalizedLink></li>
        <!-- <li><LocalizedLink to="rasa">Rasa</LocalizedLink></li> -->
        <li><LocalizedLink to="galeria">{{ $t('nav.gallery') }}</LocalizedLink></li>
        <li><LocalizedLink to="kontakt">{{ $t('nav.contact') }}</LocalizedLink></li>
      </ul>
    </div>
  </nav>
</template>

<script>
import LocalizedLink from '../components/LocalizedLink.vue'

export default {
  name: 'NavbarComponent',
  components: {
    LocalizedLink
  },
  methods: {
    toggleNav() {
      this.$el.querySelector('.nav-links').classList.toggle('active');
    },
    toggleLocale() {
      if(this.$i18n.locale != "pl"){
        this.$i18n.locale = "pl";
      }else{
        this.$i18n.locale = "en";
      }

      const routeName = this.$route.name;
      const lang = this.$i18n.locale == 'pl' ? '' : 'en';

      this.$router.push({ name: routeName, params: { lang } });
    }
  },
  mounted() {
    this.$el.querySelector('.navbar-toggle').addEventListener('click', this.toggleNav);
  },
  beforeUnmount() {
    this.$el.querySelector('.navbar-toggle').removeEventListener('click', this.toggleNav);
  }
};
</script>

<style lang="scss">
@import '../assets/scss/navbar.scss';
</style>