<template>
    <router-link :to="localizedPath(to)">
        <slot></slot>
    </router-link>
</template>
  
<script>
export default {
name: 'LocalizedLink',
props: {
    to: {
        type: String,
        required: true
    }
},
methods: {
    localizedPath(path) {
        const currentLang = this.$i18n.locale == 'pl' ? '' : 'en/';
        return `/${currentLang}${path}/`;
    }
}
};
</script>