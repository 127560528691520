<template>
  <div class="rasa">
    <h1>Welcome to Our Website</h1>

  </div>
</template>

<script>
export default {
  name: 'RasaPage',
};
</script>

<style scoped>

</style>