<template>
  <CookieConsent />
  <PopupComponent />
  <NavbarComponent />
  <div class="galeria-page">
    <div class="baner">
      <img src="../assets/img/baner-galeria.jpg">
    </div>
    <div class="container">
      <!-- <div class="box">
        <img src="../assets/img/gallery4.jpg">
      </div>
      <div class="box">
        <img src="../assets/img/rebelle.jpg">
      </div>
      <div class="box">
        <img src="../assets/img/gallery3.jpg">
      </div>
      <div class="box">
        <img src="../assets/img/three.jpg">
      </div>
      <div class="box">
        <img src="../assets/img/rosie.jpg">
      </div>
      <div class="box">
        <img src="../assets/img/gallery1.jpg">
      </div>
      <div class="box">
        <img src="../assets/img/winnie.jpg">
      </div>
      <div class="box">
        <img src="../assets/img/gallery5.jpg">
      </div> -->
      <div class="box" v-for="(image, index) in images.gallery" :key="index"
        :style="{ backgroundImage: 'url(' + image + ')' }">
      </div>
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import PopupComponent from '../components/PopupComponent.vue'
import NavbarComponent from '../components/NavbarComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'
import CookieConsent from '@/components/CookieConsent.vue';

export default {
  name: 'GaleriaPage',
  components: {
    PopupComponent,
    NavbarComponent,
    FooterComponent,
    CookieConsent
  },
  data() {
    return {
      images: {
        gallery: [
          require('@/assets/img/gallery4.jpg'),
          require('@/assets/img/gallery3.jpg'),
          require('@/assets/img/rosie.jpg'),

          require('@/assets/img/gallery1.jpg'),
          require('@/assets/img/gallery5.jpg'),
          require('@/assets/img/rebelle.jpg'),

          require('@/assets/img/gallery6.png'),
          require('@/assets/img/three.jpg'),
          require('@/assets/img/winnie.jpg'),
        ]
      }
    };
  }
};
</script>

<style lang="scss">
@import '../assets/scss/galeria.scss';
</style>