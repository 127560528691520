<template>
    <PopupComponent />
    <NavbarComponent />
    <div class="kontakt">
        <div class="baner">
            <img src="../assets/img/baner-kontakt.jpg">
        </div>
        <div class="grey-bcg">
            <div class="map" ref="map" style=""></div>
            <div class="side-txt">
                <h1>
                    {{ $t('contact.title') }}
                </h1>
                <div class="contact">
                    <img src="../assets/icons/phone.svg">
                    <a href="tel: +48 607 550 068">+48 607 550 068</a>
                </div>
                <div class="contact">
                    <img src="../assets/icons/mail.svg">
                    <a href="mailto: m.krankiewicz@valleeducastor.pl">m.krankiewicz@valleeducastor.pl</a>
                </div>
                <div class="contact">
                    <img src="../assets/icons/address.svg">
                    <p>
                        Mściszewo 40E,<br>
                        62-095 Mściszewo
                    </p>
                </div>
            </div>
        </div>
    </div>
    <FooterBlank />
</template>

<script>

import PopupComponent from '../components/PopupComponent.vue'
import L from 'leaflet';
import NavbarComponent from '../components/NavbarComponent.vue';
import 'leaflet/dist/leaflet.css';
import pawIcon from '@/assets/icons/mapicon.svg';
import FooterBlank from '@/components/FooterBlank.vue';

export default {
    name: 'KontaktPage',
    components: {
        PopupComponent,
        NavbarComponent,
        FooterBlank
    },
    mounted() {
        this.initMap();
    },
    methods: {
        initMap() {
            const map = L.map(this.$refs.map, {
                center: [52.580222214875604, 16.969419475639096],
                zoom: 13
            });

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '© OpenStreetMap contributors'
            }).addTo(map);
            const customIcon = L.icon({
                iconUrl: pawIcon,
                iconSize: [35, 35],
                iconAnchor: [17, 35],
                popupAnchor: [1, -34]
            });

            L.marker([52.580222214875604, 16.969419475639096], { icon: customIcon }).addTo(map)
                .bindPopup('Mściszewo 40E<br>62-095 Mściszewo');
        }
    }
};


</script>

<style lang="scss">
@import '../assets/scss/kontakt.scss';
@import 'leaflet/dist/leaflet.css';
</style>