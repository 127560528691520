<template>
    <PopupComponent />
    <NavbarComponent />
    <div class="nasze-psy">
        <div class="baner">
            <img src="../assets/img/baner-hodowla.jpg">
        </div>
        <div class="intro mw">
            <h2 v-html="$t('ourDogs.title')"></h2>
            <p  v-html="$t('ourDogs.text')" style="text-align: justify;"></p>
        </div>
        <div class="grey-bcg dog">
            <div class="side-img right">
                <img src="../assets/img/winnie.jpg">
            </div>
            <div class="side-txt">
                <h2>
                    <span class="green">
                        {{ $t('dogs.winnie.name') }}
                    </span>
                    {{ $t('dogs.winnie.farm') }}
                </h2>
                <div class="flex-parent">
                    <div class="details">
                        <img src="../assets/icons/birthday-cake.svg">
                        <p>{{ $t('others.age') }}: {{ $t('dogs.winnie.age') }}</p>
                    </div>
                    <div class="details">
                        <img src="../assets/icons/import.svg">
                        <p>{{ $t('others.import') }}: {{ $t('dogs.winnie.import') }}</p>
                    </div>
                </div>
                <p v-html="$t('dogs.winnie.description')"></p>
                <div class="flex-parent">
                    <button class="rodowod" @click="openRodowod('winnie')">
                        {{ $t('button.lineage') }} <img src="../assets/icons/download.svg">
                    </button>
                    <div class="champion">
                        <img src="../assets/icons/champion.svg">
                        <p>{{ $t('others.championship') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="dog">
            <div class="side-txt">
                <h2>
                    <span class="green">
                        {{ $t('dogs.rebelle.name') }}
                    </span>
                    {{ $t('dogs.rebelle.farm') }}
                </h2>
                <div class="flex-parent">
                    <div class="details">
                        <img src="../assets/icons/birthday-cake.svg">
                        <p>{{ $t('others.age') }}: {{ $t('dogs.rebelle.age') }}</p>
                    </div>
                    <div class="details">
                        <img src="../assets/icons/import.svg">
                        <p>{{ $t('others.import') }}: {{ $t('dogs.rebelle.import') }}</p>
                    </div>
                </div>
                <p v-html="$t('dogs.rebelle.description')"></p>
                <div class="flex-parent">
                    <button class="rodowod" @click="openRodowod('rebelle')">
                        {{ $t('button.lineage') }} <img src="../assets/icons/download.svg">
                    </button>
                    <div class="champion">
                        <img src="../assets/icons/champion.svg">
                        <p>{{ $t('others.championship') }}</p>
                    </div>
                </div>
            </div>
            <div class="side-img">
                <img src="../assets/img/rebelle.jpg">
            </div>
        </div>
        <div class="grey-bcg dog">
            <div class="side-img right">
                <img src="../assets/img/rosie.jpg">
            </div>
            <div class="side-txt">
                <h2>
                    <span class="green">
                        {{ $t('dogs.rosie.name') }}
                    </span>
                    {{ $t('dogs.rosie.farm') }}
                </h2>
                <div class="flex-parent">
                    <div class="details">
                        <img src="../assets/icons/birthday-cake.svg">
                        <p>{{ $t('others.age') }}: {{ $t('dogs.rosie.age') }}</p>
                    </div>
                    <div class="details">
                        <img src="../assets/icons/import.svg">
                        <p>{{ $t('others.origin') }}: {{ $t('dogs.rosie.origin') }}</p>
                    </div>
                </div>
                <p v-html="$t('dogs.rosie.description')"></p>
                <div class="flex-parent">
                    <button class="rodowod" @click="openRodowod('rosie')">
                        {{ $t('button.lineage') }} <img src="../assets/icons/download.svg">
                    </button>
                    <div class="champion">
                        <img src="../assets/icons/champion.svg">
                        <p>{{ $t('others.championship') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <FooterComponent />
</template>

<script>
import PopupComponent from '../components/PopupComponent.vue'
import NavbarComponent from '../components/NavbarComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'

export default {
    name: 'NaszePsy',
    components: {
        PopupComponent,
        NavbarComponent,
        FooterComponent
    },
    methods: {
        openRodowod(pdfName) {
            const pathToPdf = `pdfs/${pdfName}.pdf`;
            const url = `${window.location.origin}/${pathToPdf}`;
            window.open(url, '_blank');
        },
    },
};
</script>

<style lang="scss">
@import '../assets/scss/psy.scss';
</style>