<template>
      <!-- <CookieConsent /> -->
  <PopupComponent />
  <NavbarComponent />
  <div class="mioty">
    <div class="baner">
      <img src="../assets/img/baner-mioty.jpg">
    </div>

    <div style="max-width: 1400px; margin: 0 auto;   padding: 2rem;">
      <h1>
        {{ $t('litters.title') }}
      </h1>
      <p v-html="$t('litters.text')"></p>
    </div>

    <div class="puppy-wrapper">

      <div class="puppy-container" style="background-color: #fdfd96;">
        <div class="img-container"  :style="{backgroundImage: 'url(' + images.bora + ')' }">
          <!-- <img src="../assets/img/bora.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: black;">
            BORA
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: red;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.breva + ')' }">
          <!-- <img src="../assets/img/breva.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: white;">
            BREVA
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: #800000;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.zonda + ')' }">
          <!-- <img src="../assets/img/zonda.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="text-align: center; color: white;">
            ZONDA
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: orange;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.aspre + ')' }">
          <!-- <img src="../assets/img/aspre.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: white;">
            ASPRE
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: pink;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.alize + ')' }">
          <!-- <img src="../assets/img/alize.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: black;">
            ALIZE
          </h3>
        </div>
      </div>

    </div>

    <div class="puppy-wrapper" style="padding-top: 2rem;">

      <div class="puppy-container" style="background-color: darkblue;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.tivano + ')' }">
          <!-- <img src="../assets/img/tivano.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: white;">
            TIVANO
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: lightskyblue;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.zefir + ')' }">
          <!-- <img src="../assets/img/zefir.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: black;">
            ZEFIR
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: lightgreen;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.mistral + ')' }">
          <!-- <img src="../assets/img/mistral.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="text-align: center; color: black;">
            MISTRAL
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: #7f00ff;">
        <div class="img-container" :style="{backgroundImage: 'url(' + images.yugo + ')' }">
          <!-- <img src="../assets/img/yugo.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: white;">
            YUGO
          </h3>
        </div>
      </div>

      <div class="puppy-container" style="background-color: darkgreen;">
        <div class="img-container"  :style="{backgroundImage: 'url(' + images.sirocco + ')' }">
          <!-- <img src="../assets/img/sirocco.webp"> -->
        </div>
        <div class="txt-container">
          <h3 style="color: white;">
            SIROCCO
          </h3>
        </div>
      </div>
    </div>

  </div>
  <FooterComponent />
</template>

<script>
// import CookieConsent from '../components/CookieConsent.vue';
import PopupComponent from '../components/PopupComponent.vue';
import NavbarComponent from '../components/NavbarComponent.vue';
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'MiotyPage',
  components: {
    // CookieConsent,
    PopupComponent,
    NavbarComponent,
    FooterComponent
  },
  data() {
    return {
      images: {
        bora: require('@/assets/img/bora.webp'),
        breva: require('@/assets/img/breva.webp'),
        zonda: require('@/assets/img/zonda.webp'),
        aspre: require('@/assets/img/aspre.webp'),
        alize: require('@/assets/img/alize.webp'),
        tivano: require('@/assets/img/tivano.webp'),
        zefir: require('@/assets/img/zefir.webp'),
        mistral: require('@/assets/img/mistral.webp'),
        yugo: require('@/assets/img/yugo.webp'),
        sirocco: require('@/assets/img/sirocco.webp')
      }
    };
  }
};
</script>

<style lang="scss">
@import '../assets/scss/mioty.scss';
</style>