import { createRouter, createWebHistory } from "vue-router";
import HomePage from "./views/HomePage.vue";
import NaszePsy from "./views/NaszePsy.vue";
import MiotyPage from "./views/MiotyPage.vue";
import RasaPage from "./views/RasaPage.vue";
import GaleriaPage from "./views/GaleriaPage.vue";
import KontaktPage from "./views/KontaktPage.vue";

const routes = [
  {
    path: "/:lang(en|pl)?",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/:lang(en|pl)?/nasze-psy",
    name: "NaszePsy",
    component: NaszePsy,
  },
  {
    path: "/:lang(en|pl)?/mioty",
    name: "MiotyPage",
    component: MiotyPage,
  },
  {
    path: "/:lang(en|pl)?/rasa",
    name: "RasaPage",
    component: RasaPage,
  },
  {
    path: "/:lang(en|pl)?/galeria",
    name: "GaleriaPage",
    component: GaleriaPage,
  },
  {
    path: "/:lang(en|pl)?/kontakt",
    name: "KontaktPage",
    component: KontaktPage,
  },
  {
    path: '/:catchAll(.*)',
    component: HomePage
  }
];

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

const router = createRouter({
  history: createWebHistory(),
  routes,
});


export default router;
