<template>
    <div class="social">
        <div class="social__elem">
            <a href="https://www.facebook.com/profile.php?id=61553558183045" target="_blank">
                <img src="../assets/icons/side-facebook.svg">
            </a>
        </div>
        <div class="social__elem">
            <a href="tel:+48 607 550 068">
                <img src="../assets/icons/side-phone.svg">
            </a>
        </div>
    </div>
</template>

<style lang="scss">
@import '../assets/scss/social.scss';
</style>