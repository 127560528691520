<template>
    <div class="footer">
        <div class="logo">
            <img src="../assets/icons/logo-black.svg">
        </div>
        <div class="side-txt">
            <div class="contact">
                <img src="../assets/icons/phone.svg">
                <a href="tel: +48 607 550 068">+48 607 550 068</a>
            </div>
            <div class="contact">
                <img src="../assets/icons/mail.svg">
                <a href="mailto: m.krankiewicz@valleeducastor.pl">m.krankiewicz@valleeducastor.pl</a>
            </div>
            <div class="contact">
                <img src="../assets/icons/address.svg">
                <p>
                    Mściszewo 40E,<br>
                    62-095 Mściszewo
                </p>
            </div>
        </div>
    </div>
    <div class="blue-div">
    </div>
</template>

<script>
export default {
    name: 'FooterComponent',
}
</script>

<style lang="scss">
@import '../assets/scss/footer.scss';
</style>