<template>
  <PopupComponent />
  <NavbarComponent />
  <div class="home">
    <div class="baner" :style="{ backgroundImage: 'url(' + images.baner + ')' }">
      <!-- <img src="../assets/img/baner-main.jpg"> -->
      <div class="intro">
        <LocalizedLink to="galeria">
          <div class="subpage galeria" :class="{'eng' : $i18n.locale == 'en'}"></div>
        </LocalizedLink>
        <LocalizedLink to="mioty">
          <div class="subpage mioty" :class="{'eng' : $i18n.locale == 'en'}"></div>
        </LocalizedLink>
        <LocalizedLink to="nasze-psy">
          <div class="subpage nasze-psy" :class="{'eng' : $i18n.locale == 'en'}"></div>
        </LocalizedLink>
      </div>
    </div>
    <div class="grey-bcg">
      <div class="dog-card-wrapper">
        <div style="display: flex; align-items: center; justify-content: center;">
          <h1 class="mw">{{ $t('homepage.ourDogs.title') }}</h1>
        </div>
        <div class="dog-card-container mw">
          <div class="dog-card">
            <div class="img" :style="{ backgroundImage: 'url(' + images.winnie + ')' }">
              <!-- <img src="../assets/img/winnie.jpg"> -->
            </div>
            <div class="txt">
              <div class="dog-card-h2">

              <h2>
                <span class="green">
                  {{ $t('dogs.winnie.name') }}
                </span>
                <br>
                {{ $t('dogs.winnie.farm') }}
              </h2>

              </div>
              <p class="green">
                {{ $t('dogs.winnie.information') }}
              </p>
              <div class="details">
                <div class="icon" :style="{ backgroundImage: 'url(' + images.birth + ')' }"></div>
                <!-- <img src="../assets/icons/birthday-cake.svg"> -->
                <p>{{ $t('others.age') }}: {{ $t('dogs.winnie.age') }}</p>
              </div>
              <div class="details">
                <!-- <img src="../assets/icons/import.svg"> -->
                <div class="icon import" :style="{ backgroundImage: 'url(' + images.import + ')' }"></div>
                <p>{{ $t('others.import') }}: {{ $t('dogs.winnie.import') }}</p>
              </div>
              <button class="more">
                <LocalizedLink to="nasze-psy">
                  {{ $t('button.findOutMore') }}
                </LocalizedLink>
              </button>
            </div>
          </div>
          <div class="dog-card">
            <div class="img" :style="{ backgroundImage: 'url(' + images.rebelle + ')' }">
              <!-- <img src="../assets/img/rebelle.jpg"> -->
            </div>
            <div class="txt">
              <div class="dog-card-h2">
              <h2>
                <span class="green">
                  {{ $t('dogs.rebelle.name') }}
                </span>
                <br>
                {{ $t('dogs.rebelle.farm') }}
              </h2>
            </div>
              <p class="green">
                {{ $t('dogs.rebelle.information') }}
              </p>
              <div class="details">
                <!-- <img src="../assets/icons/birthday-cake.svg"> -->
                <div class="icon" :style="{ backgroundImage: 'url(' + images.birth + ')' }"></div>
                <p>{{ $t('others.age') }}: {{ $t('dogs.rebelle.age') }}</p>
              </div>
              <div class="details">
                <!-- <img src="../assets/icons/import.svg"> -->
                <div class="icon import" :style="{ backgroundImage: 'url(' + images.import + ')' }"></div>
                <p>{{ $t('others.import') }}: {{ $t('dogs.rebelle.import') }}</p>
              </div>
              <button class="more">
                <LocalizedLink to="nasze-psy">
                  {{ $t('button.findOutMore') }}
                </LocalizedLink>
              </button>
            </div>
          </div>
          <div class="dog-card">
            <div class="img" :style="{ backgroundImage: 'url(' + images.rosie + ')' }">
              <!-- <img src="../assets/img/rosie.jpg"> -->
            </div>
            <div class="txt">
              <div class="dog-card-h2">
              <h2>
                <span class="green">
                  {{ $t('dogs.rosie.name') }}
                </span>
                <br>
                {{ $t('dogs.rosie.farm') }}
              </h2>
              </div>
              <p class="green">
                {{ $t('dogs.rosie.information') }}
              </p>
              <div class="details">
                <!-- <img src="../assets/icons/birthday-cake.svg"> -->
                <div class="icon" :style="{ backgroundImage: 'url(' + images.birth + ')' }"></div>
                <p>{{ $t('others.age') }}: {{ $t('dogs.rosie.age') }}</p>
              </div>
              <div class="details">
                <!-- <img src="../assets/icons/import.svg"> -->
                <div class="icon import" :style="{ backgroundImage: 'url(' + images.import + ')' }"></div>
                <p>{{ $t('others.origin') }}: {{ $t('dogs.rosie.origin') }}</p>
              </div>
              <button class="more">
                <LocalizedLink to="nasze-psy">
                  {{ $t('button.findOutMore') }}
                </LocalizedLink>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-img">
        <img src="../assets/img/middle-img.jpg">
      </div>
      <div class="about mw">
        <div class="side-txt">
          <h2>
            {{ $t('homepage.aboutUs.title') }}
          </h2>
          <p v-html="$t('homepage.aboutUs.text')"></p>
        </div>
        <div class="side-img">
          <img src="../assets/img/three.jpg" style="border-radius: 20px 20px 20px 0;">
        </div>
      </div>
    </div>
    <div class="mw paw-wrapper">
      <div class="one-container">
        <img src="../assets/img/one.jpg">
      </div>
      <div class="paw-container">
        <img src="../assets/icons/paw.svg">
      </div>
    </div>
    <div class="galeria">
      <div class="container">
        <div class="box" style="height: 5rem;">
          <h2>
            {{ $t('homepage.gallery.title') }}
          </h2>
          <div class="side-txt">
            <LocalizedLink to="galeria">
              {{ $t('homepage.gallery.button') }}<img src="../assets/icons/galery-icon.svg">
            </LocalizedLink>
          </div>
        </div>
        <!-- <div class="box">
          <img src="../assets/img/gallery4.jpg">
        </div>
        <div class="box">
          <img src="../assets/img/gallery3.jpg">
        </div>
        <div class="box">
          <img src="../assets/img/gallery1.jpg">
        </div>
        <div class="box">
          <img src="../assets/img/gallery5.jpg">
        </div> -->
        <div class="box" v-for="(image, index) in images.galleryImages" :key="index"
          :style="{ backgroundImage: 'url(' + image + ')' }">
        </div>
      </div>
    </div>
  </div>
  <FooterComponent />
</template>

<script>
import LocalizedLink from '../components/LocalizedLink.vue'
import PopupComponent from '../components/PopupComponent.vue'
import NavbarComponent from '../components/NavbarComponent.vue'
import FooterComponent from '../components/FooterComponent.vue'

export default {
  name: 'HomePage',
  components: {
    LocalizedLink,
    PopupComponent,
    NavbarComponent,
    FooterComponent
  },
  data() {
    return {
      images: {
        baner: require('@/assets/img/baner-main.jpg'),

        birth: require('@/assets/icons/birthday-cake.svg'),
        import: require('@/assets/icons/import.svg'),

        winnie: require('@/assets/img/winnie.jpg'),
        rebelle: require('@/assets/img/rebelle.jpg'),
        rosie: require('@/assets/img/rosie.jpg'),
        galleryImages: [
          require('@/assets/img/gallery4.jpg'),
          require('@/assets/img/gallery3.jpg'),
          require('@/assets/img/gallery1.jpg'),
          require('@/assets/img/gallery5.jpg')
        ]
      }
    };
  }
};
</script>

<style lang="scss">
@import '../assets/scss/home.scss';
</style>