<template>
  <div>
    <SocialComponent />
    <router-view />
    <CookieConsent />
  </div>
</template>

<script>
import CookieConsent from '@/components/CookieConsent.vue';
import SocialComponent from '@/components/SocialComponent.vue';

export default {
  name: 'App',
  components: {
    CookieConsent,
    SocialComponent
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/global.scss';
</style>
